import React, { useEffect } from "react";

import PrimaryButton from "./../../components/PrimaryButton";
import { VoidFunction } from "types/generic";
import QuestionTopBar from "modules/difficulty/QuestionTopBar";
import QuestionHeader from "modules/difficulty/QuestionHeader";

interface Props {
  intro: string;
  onIntroContinueClicked: VoidFunction;
  percentComplete: number;
  title: string;
}

const QuestionIntro = (props: Props) => {
  const { intro, percentComplete, onIntroContinueClicked, title } = props;

  useEffect(() => {
    // NOTE: This is necessary for very long intro texts, to prevent component loading midway down through the text
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <QuestionTopBar percentComplete={percentComplete} />
      <div className="question column">
        <QuestionHeader content={title} intro={intro} />

        <div className="question__action-container columns is-mobile">
          <PrimaryButton
            onClick={onIntroContinueClicked}
            readyToCheck
            title={"Continue"}
          />
        </div>
      </div>
    </>
  );
};

export default QuestionIntro;
