import axios, { AxiosInstance } from "axios";

export class Api {
  baseConfig: {
    timeout: number;
    headers: object;
    validateStatus: (status: number) => boolean;
    baseURL: string;
  };

  baseHeaders: {
    Accept: string;
    "Content-Type": string;
  };

  baseUrl: string;

  utility: AxiosInstance;

  constructor() {
    this.baseHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    this.baseUrl = process.env.APIHOST;

    this.baseConfig = {
      baseURL: this.baseUrl,
      timeout: 30000,
      headers: this.baseHeaders,
      validateStatus: (status: number) => {
        return status >= 200 && status < 300;
      },
    };

    this.utility = axios.create(this.baseConfig);
  }

  setAuthUtility(authToken: string) {
    this.utility.defaults.headers.common["Authorization"] = authToken;
  }

  setBaseUtility() {
    this.utility.defaults.headers.common["Authorization"] = undefined;
  }
}

const api = new Api();

export default api;
