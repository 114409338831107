import React from "react";
import { renderFormulaContent, renderFormulaStyles } from 'utils/formula/formulaHelper'

interface Props {
  content: string;
  content_images?: any;
  currentQuestion?: number;
  image_data?: string;
  image_type?: string;
  intro?: string;
  sub_content?: string;
  sub_content_images?: any
  totalQuestions?: number;
}

const QuestionHeader = (props: Props) => {
  const {
    content,
    content_images,
    currentQuestion,
    image_data,
    image_type,
    intro,
    sub_content,
    sub_content_images,
    totalQuestions,
  } = props;

  const headerSize = 2.5;
  const headerMinHeight = 22;
  let questionItems = renderFormulaContent(content, content_images, headerSize, headerMinHeight);
  let questionImageStyle = renderFormulaStyles(content_images, headerSize, headerMinHeight);

  const subHeaderSize = 3.5;
  const subHeaderMinHeight = 36;
  let subQuestionItems = renderFormulaContent(sub_content, sub_content_images, subHeaderSize, subHeaderMinHeight);
  let subQuestionImageStyle = renderFormulaStyles(sub_content_images, subHeaderSize, subHeaderMinHeight);

  let imageData = null;
  if (image_type) {
    imageData = image_data;
    if (image_type.toLowerCase().includes("svg")) {
      imageData = btoa(imageData);
    }
    imageData = "data:" + image_type + ";base64," + imageData;
  }

  if (intro) {
    return (
      <div className="question__header-intro">
        <p className="question__intro">{intro}</p>
      </div>
    )
  }

  return (
    <div className="question__header">
      {currentQuestion && totalQuestions && (
        <p className="question__count">
          Question {currentQuestion} of {totalQuestions}
        </p>
      )}
      <h2 className="question__content" style={questionImageStyle}>{questionItems}</h2>
      {!!sub_content && <p className="question__sub-content" style={subQuestionImageStyle}>{subQuestionItems}</p>}
      {!!intro && <p className="question__intro">{intro}</p>}
      {!!image_type && <img src={imageData} />}
    </div>
  );
};

export default QuestionHeader;
