import React, { useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";

import LessonResults from "modules/difficulty/LessonResults";
import QuestionBlock from "modules/difficulty/QuestionBlock";
import { Quiz } from "types/quiz";
import { Child } from "types/child";
import { composeWithRedux } from "../../../packs/application.js";
import { useForceUpdate } from "utils/forceUpdate";
import { Answer } from "types/answer.js";

interface Props {
  child: Child;
  quiz: Quiz;
  groupId: number;
  quizType: string;
}

const QuizScreen = (props: Props) => {
  const { child, quiz, groupId, quizType } = props;
  const { questions } = quiz;
  const dispatch = useDispatch();

  const isReadingType: boolean = quizType === "reading_comprehension";
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [isShowingResults, setIsShowingResults] = useState<boolean>(false);
  const forceUpdate = useForceUpdate();
  
  const isFinalQuestion: boolean = currentQuestion === quiz.questions_count - 1;
  const percentComplete: number = Math.round(
    ((currentQuestion + 1) / quiz.questions_count) * 100
  );

  const checkAnswer = (selectedAnswer) => {
    const {answers} = questions[currentQuestion];
    // set actual answer
    questions[currentQuestion].answer = answers.find(e => e.id === selectedAnswer);
    
    if (isFinalQuestion) {
      setIsShowingResults(true);
    }
    else {
      onContinueClicked();
    }
  };

  const resetQuiz = () => {
    setCurrentQuestion(0);
    setIsShowingResults(false);
  };

  const setLoading = () => {
    quiz.loading = true;
  }

  const loadNextDataChunk = (loadQuestions = false) => {
    if (!quiz.requested_question) {
      quiz.requested_question = 2;
    }

    let nextImagePos = quiz.images_count;
    if (quiz.images_count > 0) {
      nextImagePos = quiz.images.length;
    }

    let nextQuestionPos = -1;

    let hasDataToLoad = nextImagePos < quiz.images_count;
    if (!hasDataToLoad && loadQuestions) {
      nextQuestionPos = quiz.questions.length;
      if (quiz.questions_count > 0) {
        nextQuestionPos = quiz.questions.length;
      }
      let lastPosition = nextQuestionPos + 5;
      if (quiz.requested_question < lastPosition) {
        quiz.requested_question = lastPosition;
        hasDataToLoad = nextQuestionPos < quiz.questions_count;
      }
    }

    if (hasDataToLoad) {
      dispatch({
        type: 'GET_QUIZ_SAGA',
        payload: {
          id: quiz.id,
          image_position: nextImagePos,
          question_position: nextQuestionPos,
          onSuccess: data => {
            quiz.images = quiz.images.concat(data.images);
            quiz.questions = quiz.questions.concat(data.questions);
            
            if (quiz.loading) {
              quiz.loading = false;
              forceUpdate(); // force re-render
            }

            loadNextDataChunk();
          },
        },
      });
    }
  }

  const onContinueClicked = () => {
    loadNextChunk();
    setCurrentQuestion((currentQuestion) => currentQuestion + 1);
  }

  const loadNextChunk = () => {
    const nextQuestionIndex = currentQuestion + 1;
    let probationIndex = nextQuestionIndex + 5;
    if (probationIndex > quiz.questions_count) {
      probationIndex = quiz.questions_count;
    }

    // console.log("-probationIndex- " + probationIndex + " - " + quiz.requested_question + " - " + quiz.questions.length);
    if (probationIndex > quiz.requested_question && probationIndex > quiz.questions.length) {

      // check on images first
      if (quiz.images_count === quiz.images.length) {
        loadNextDataChunk(true);
      }
    }
  }

  if (!quiz.requested_question) {
    loadNextDataChunk();
  }

  return (
    <div className="quiz">
      {isShowingResults ? (
        <LessonResults
          childId={child.id}
          quizId={quiz.id}
          lessonId={groupId}
          resetQuiz={resetQuiz}
          questions={questions}
          isReadingType={isReadingType}
        />
      ) : (
        <QuestionBlock
          checkAnswer={checkAnswer}
          currentQuestion={currentQuestion}
          quiz={quiz}
          quizType={quizType}
          percentComplete={percentComplete}
          question={questions[currentQuestion]}
          setLoading={setLoading}
          loadNextChunk={loadNextChunk}
          totalQuestions={quiz.questions_count}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizScreen);

export default (props) => composeWithRedux(ConnectedComponent, props);
