import React, { useMemo } from "react";
import classnames from "classnames";

import { Answer } from "types/answer";
import { VoidFunction } from "types/generic";

import { renderFormulaContent, renderFormulaStyles } from 'utils/formula/formulaHelper'

import imgIncorrect from "assets/images/icons/incorrect.svg";
import imgCorrect from "assets/images/icons/correct.svg";

interface Props {
  answer: Answer;
  isReadingType: boolean;
  isShowingAnswer?: boolean;
  selectedAnswer: number;
  setSelectedAnswer?: VoidFunction;
}

const AnswerButton = (props: Props) => {
  const {
    answer,
    isReadingType,
    isShowingAnswer,
    selectedAnswer,
    setSelectedAnswer,
  } = props;
  const { correct, content, content_images } = answer;

  var size = 2;
  var minHeight = 16;
  let items = renderFormulaContent(content, content_images, size, minHeight);
  let style = renderFormulaStyles(answer.content_images, size, minHeight);

  const isIncorrect: boolean = useMemo(() => {
    if (isShowingAnswer && selectedAnswer === answer.id && !correct) {
      return true;
    } else {
      return false;
    }
  }, [isShowingAnswer]);

  const isCorrect: boolean = useMemo(() => {
    if (isShowingAnswer && correct) {
      return true;
    } else {
      return false;
    }
  }, [isShowingAnswer]);

  const renderLabel = () => {
    if (isIncorrect) {
      return (
        <div className="answer__label">
          <img
            className="answer__icon"
            src={imgIncorrect}
          />
          <span className="answer__incorrect-label">Incorrect</span>
        </div>
      );
    }

    if (isCorrect) {
      return (
        <div className="answer__label">
          <img
            className="answer__icon"
            src={imgCorrect}
          />
          <span className="answer__correct-label">Correct</span>
        </div>
      );
    }
  };

  const buttonClass = classnames("answer", {
    "answer__is-selected":
      answer.id === selectedAnswer && !isIncorrect && !isCorrect,
    "answer__is-incorrect": isIncorrect,
    "answer__is-correct": isCorrect,
  });

  return (
    <div className={classnames({
        "col-sm": !isShowingAnswer,
        "col-result": isShowingAnswer
      })}>
      <button
        className={buttonClass}
        disabled={isShowingAnswer}
        onClick={() => setSelectedAnswer(answer.id)}
      >
        <span
          className={classnames("answer__title", {
            "answer__title--is-reading": isReadingType,
          })}
          style={style}
        >
          {items}
        </span>
        {renderLabel()}
      </button>
    </div>
  );
};

export default AnswerButton;