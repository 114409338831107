import React from "react";
import {Provider} from "react-redux";
import WebpackerReact from "webpacker-react";
import "react-image-lightbox/style.css";

import store from "../src/actions/configureStore";
import QuizScreen from "../src/contexts/quiz/QuizScreen";
import SetupScreen from "../src/contexts/setup/SetupScreen";

// USER TIME ZONE JS
const timezone = require("jstimezonedetect");
const userTimezone = timezone.determine().name();
// USER TIME ZONE JS

document.addEventListener("DOMContentLoaded", () => {
    const userTimeZoneObject = document.getElementById("user_time_zone");
    if (userTimeZoneObject !== null) {
        userTimeZoneObject.value = userTimezone;
    }

    // WebClip Fullscreen workaround
    const a = document.getElementsByTagName("a");
    for (let i = 0; i < a.length; i++) {
        a[i].onclick = function () {
            window.location = this.getAttribute("href");
            return false
        }
    }
});

export function navigateTo(url, e) {
    window.location.href = url;
    e.preventDefault();
    return false;
}

export function goHome(e) {
    return navigateTo("/app/groups", e);
}

export const composeWithRedux = (Component, props) => (
    <Provider store={store}>
        <Component {...props} />
    </Provider>
);

WebpackerReact.setup({QuizScreen});
WebpackerReact.setup({SetupScreen});
