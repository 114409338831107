import React, { ReactNode } from "react";

export const boldAfterFirstWord = (text: string): ReactNode => {
  const spaceIndex = text.indexOf(" ");
  const firstWord = text.substring(0, spaceIndex);
  const restOfText = text.substring(spaceIndex + 1, text.length);

  return (
    <>
      <span>{firstWord} </span>
      <span className="is-bold">{restOfText}</span>
    </>
  );
};
