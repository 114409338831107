import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PrimaryButton from "./../../components/PrimaryButton";
import { VoidFunction } from "types/generic";
import LightboxImage from "components/LightboxImage";
import { Device } from "types/device";
import usePostRequest from "api/usePostRequest";
import { Child } from "types/child";
import { Plan } from "types/plan";

import imgCaret from "assets/images/icons/caret-left.svg";
import imgApp from "assets/images/setup/icon.svg";
import imgDownload from "assets/images/setup/download-mdm.png";
import imgStep0 from "assets/images/setup/settings_step_0.png";
import imgStep1 from "assets/images/setup/settings_step_1.png";
import imgStep2 from "assets/images/setup/settings_step_2.png";
import imgStep3 from "assets/images/setup/settings_step_3.png";
import imgStep4 from "assets/images/setup/settings_step_4.png";
import imgStep5 from "assets/images/setup/settings_step_5.png";
import imgStep6 from "assets/images/setup/settings_step_6.png";

interface Props {
  onParentContinueClicked: VoidFunction;
  device: Device;
  child: Child;
  setup_position: number;
  has_lock_permission: boolean;
  plan: Plan;
}

const OnBoardingBlock = (props: Props) => {
  const dispatch = useDispatch();

  const {
    device,
    child,
    setup_position,
    has_lock_permission,
    plan,
  } = props;

  const [stepPosition, setStepPosition] = useState(0);
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [hasPosition, setHasPosition] = useState<boolean>(false);
  const [step2ImagePosition, setStep2ImagePosition] = useState(0);
  const [isStep3Loading, setStep3Loading] = useState<boolean>(true);
  const [isMdmEnrolled, setMdmEnrolled] = useState<boolean>(false);
  const [checkStatus] = usePostRequest({
    dispatch,
    dispatchToStore: false,
    url: '/app/setup/status',
    body: {
      id: device.id,
    },
    onSuccess: (data) => {
      setStep3Loading(false);
      setMdmEnrolled(data == "yes");
    },
    onFailure: (err) => {
      setStep3Loading(false);
      setMdmEnrolled(false);
    },
  });

  const onDownloadClicked = () => {
    setTimeout(function(){
      setCanContinue(true);
    }, 1500);
    
    window.location.href = '/enroll?device_uuid=' + device.uuid;
  }

  const onStep2PrevImageClicked = () => {
    let position = step2ImagePosition - 1;
    if (position >= 0) {
      setStep2ImagePosition(position);
    }
  }

  const onStep2NextImageClicked = () => {
    let position = step2ImagePosition + 1;
    if (position < 7) {
      setStep2ImagePosition(position);
    }
  }

  const onBackStep1Clicked= () => {
    setStepPosition(0);
    setCanContinue(false);
  }

  const setNewPosition = (position) => {
    if (position >= 0 && position <= 2) {
      setStepPosition(position);
      if (position == 1) {
        setCanContinue(true);
      } else {
        setCanContinue(false);
      }
      
    
      if (position == 2) {
        checkStatus();
      }
    }
  }

  const onBackClicked = () => {
    setNewPosition(stepPosition - 1);
  }

  const onReloadPage = () => {
    window.location.href = window.location.href;
  }

  const onContinueClicked = () => {
    if (canContinue) {
      setNewPosition(stepPosition + 1);
    }
  }

  const onCheckAgainClicked = () => {
    checkStatus();
    setStep3Loading(true);
  }

  const onAppClicked = () => {
    const url = '/app?child_id=' + child.id + '&pin=' + child.pin + '&initial=true' + '&key=' + device.uuid;
    window.location.href = url;
  }

  const renderLicenseLimitation = () => {
    return (
      <>
      <div className="setup__top-bar">
        <div style={{ width: "33%" }}>
        </div>

        <div className="setup__progress-bar">
          <span style={{ width: `${100}%` }}></span>
        </div>

        <div style={{ width: "33%" }} />
      </div>
    
      <div className="setup column">
        <div className="setup__header-intro">
          <h2 className="setup__content">License Limit Reached</h2>
          {plan && (
            <div>
              <p className="setup__intro setup__left">
                Your current subscription has exceeded the limit of {plan.children_limit} {plan.children_limit == 1 ? "child" : "children"}. 
                If you would like to add another child, please uninstall the MDM profile on the device you have already set up.
              </p>
              <p className="setup__intro setup__left">
                If you would like to add additional children, please upgrade your subscription to include more users.
              </p>
              <p className="setup__intro setup__left">
                Once you have upgraded your subscription or uninstalled the MDM profile on one of your devices, please reload this page.
              </p>
            </div>
          )}
          {!plan && (
            <div>
              <p className="setup__intro setup__left">
                No active subscription found. Please check your account. 
              </p>
              <p className="setup__intro setup__left">
                Once you have upgraded your subscription or uninstalled the 
                MDM profile on one of your devices, please reload this page.
              </p>
            </div>
          )}
        </div>
      
        <div className="setup__action-container columns is-mobile">
          <PrimaryButton
            title={"Reload Page"}
            onClick={onReloadPage} 
            readyToCheck={true}
          />
        </div>
      </div>
    </>
    )
  }
  const renderDownloadButton = () => {
    return (
      <>
        <div className="setup__button_download">
          <PrimaryButton 
            onClick={onDownloadClicked} 
            isPurple={true}
            showFloatFinger={!canContinue}
            title={"Download"}/>
        </div>
        {!canContinue &&
          <p className="setup__step_press_continue">
            Click "Download" button to continue
          </p>
        }
        {canContinue &&
          <p className="setup__step_press_continue">
            Click "Continue" button to show next step
          </p>
        }
      </>
    )
  }

  const renderStep2Warning = () => {
    return (
      <div className="setup__intro_flex">
        <div>
          <p className="setup__intro_warning">
            <strong>
              Once the profile is installed, your device will lock. If you need to 
              unlock the device, open the app by tapping the "L" icon and withdrawal time from the "Time Bank"
            </strong>
          </p>
        </div>
      </div>
    )
  }

  const renderAppIcon = () => {
    return (
      <div className="setup__intro_flex">
        <div className="setup__intro_middle">
            <img src={imgApp} />
        </div>
      </div>
    )
  }

  if (setup_position && !hasPosition) {
    setNewPosition(setup_position);
    setHasPosition(true);
  }

  let prevButtonActive = true;
  let nextButtonActive = true;
  
  let step2ImageDescription = "";
  if (stepPosition == 1) {
    switch (step2ImagePosition) {
      case 0:
        prevButtonActive = false;
        step2ImageDescription = "Step 1 of 7: Open iPad Settings and click General."
        break;
      case 1:
        step2ImageDescription = "Step 2 of 7: Click Profile in General Settings."
        break;
      case 2:
        step2ImageDescription = "Step 3 of 7: Select the Downloaded Profile - Learn It 2 Earn It."
        break;
      case 3:
        step2ImageDescription = "Step 4 of 7: Tap to install the Downloaded Profile."
        break;
      case 4:
        step2ImageDescription = "Step 5 of 7: Tap to install to accept the Warning message."
        break;
      case 5:
        step2ImageDescription = "Step 6 of 7: Tap 'Trust' to complete the Profile installation."
        break;
      case 6:
        nextButtonActive = false;
        step2ImageDescription = "Step 7 of 7: Done - the Profile is installed."
        break;
    }
  }

  if (!has_lock_permission) {
    return renderLicenseLimitation();
  }

  return (
    <>
      <div className="setup__top-bar">
        <div style={{ width: "33%" }}>
          {stepPosition>0 && (
            <button
              className="question__top-bar--article-button"
              onClick={onBackClicked}
            >
              <img src={imgCaret} className="setup__back_icon" />
              Back
            </button>
          )}
        </div>

        <div className="setup__progress-bar">
          <span style={{ width: `${100}%` }}></span>
        </div>

        <div style={{ width: "33%" }} />
      </div>
    
      <div className="setup column">
        <div className="setup__header-intro">
          <h2 className="setup__content">Set-Up MDM Profile (Device Permissions)</h2>
          
          {stepPosition==0 && (
            <>
              <div className="setup__main_block">
                <div className="setup__text_block">
                  <p className="setup__intro">
                    Please follow the instructions below to allow Learn It 2 Earn It 
                    (LI2EI) to lock your child’s device and prevent them from using apps
                    and games until they earn time.  
                  </p>

                  <h2 className="setup__step">
                    Step #1: <span className="setup__no_bold">Download MDM Profile</span>
                  </h2>

                  <p className="setup__step_description">
                    Download the permissions on your child’s device. A popup will ask
                    you if you want to download a configuration profile, please choose,
                    "allow".
                  </p>
                  <div className="setup__landscape_left">
                    {renderDownloadButton()}
                  </div>
                </div>
                <div className="setup__image_block">
                  <div className="setup__image_area">
                    <LightboxImage imageURL={imgDownload} />
                  </div>
                </div>
              </div>
              <div className="setup__portrait_center">
                  {renderDownloadButton()}
              </div>
            </>
          )} 
         
         {stepPosition==1 && (
            <>
              <div className="setup__main_block">
                <div className="setup__text_block">
                  <h2 className="setup__step">
                    Step #2: <span className="setup__no_bold">Install MDM Profile</span>
                  </h2>

                  <p className="setup__step_description">
                    In this Step we will install the MDM profile to this device.
                  </p>
                  <ul className="setup__list">
                    <li>Open iPad settings and in "General", click "Profile".</li>
                    <li>Select the Downloaded Profile - Learn It 2 Earn It.</li>
                    <li>Tap to install.</li>
                    <li>You must enroll your device.</li>
                  </ul>
                  <p className="setup__step_description">
                    Take a look at the images, which illustrates the whole process. 
                    Once it's done, click "Continue" to check if everything is working correctly.
                  </p>
                </div>
                <div className="setup__image_block">
                  <div className="setup__image_area_with_name">
                    {step2ImagePosition === 0 && (
                      <LightboxImage imageURL={imgStep0} />
                    )}
                    {step2ImagePosition === 1 && (
                      <LightboxImage imageURL={imgStep1} />
                    )}
                    {step2ImagePosition === 2 && (
                      <LightboxImage imageURL={imgStep2} />
                    )}
                    {step2ImagePosition === 3 && (
                      <LightboxImage imageURL={imgStep3} />
                    )}
                    {step2ImagePosition === 4 && (
                      <LightboxImage imageURL={imgStep4} />
                    )}
                    {step2ImagePosition === 5 && (
                      <LightboxImage imageURL={imgStep5} />
                    )}
                    {step2ImagePosition === 6 && (
                      <LightboxImage imageURL={imgStep6} />
                    )}
                  </div>
                  <div>
                    <div>{step2ImageDescription}</div>
                    <div className="setup__navigation">
                      <div className="setup__button_navigation">
                        <PrimaryButton 
                          onClick={onStep2PrevImageClicked}
                          isPurple={prevButtonActive}
                          title={"Previous"}/>
                      </div>
                      <div className="setup__button_navigation">
                        <PrimaryButton 
                          onClick={onStep2NextImageClicked}
                          isPurple={nextButtonActive}
                          title={"Next"}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="setup__portrait_step2_footer">
                {renderStep2Warning()}
                {renderAppIcon()}
              </div>
              <div className="setup__landscape_step2_footer">
                <div className="wide">
                  {renderStep2Warning()}
                </div>
                <div className="narrow">
                  {renderAppIcon()}
                </div>
              </div>
              {canContinue &&
                <div>
                  <p className="setup__step_press_continue">
                    Click the "Continue" button when MDM profile is installed
                  </p>
                </div>
              }
            </>
          )} 

          {stepPosition==2 && (
            <div>
              <h2 className="setup__step">
                Step #3: <span className="setup__no_bold">Validate Installation and Lock Device</span>
              </h2>
              
              {isStep3Loading && (
                <div>
                  {!isMdmEnrolled && (
                    <p className="setup__step_description">
                      Please wait, we are checking profile installation.
                    </p>
                  )}
                  
                  <div className="loader setup__loader">
                  </div>
                </div>
              )}
              {!isStep3Loading && (
                <div>
                  {isMdmEnrolled && (
                    <div>
                    <p className="setup__step_description">
                      Congratulations! Device is locked. It's time to open LI2EI app. 
                    </p>
                    <div className="setup__button_download">
                      <PrimaryButton 
                        onClick={onAppClicked} 
                        isPurple={true}
                        title={"Open LI2EI App"}/>
                    </div>
                  </div>
                  )}

                  {!isMdmEnrolled && (
                    <div>
                      <p className="setup__step_description">
                        <strong>Unfortunately,</strong> the MDM profile is not installed correctly. Please check out the configuration.
                      </p>

                      <ul className="setup__list">
                        <li><a className="setup__inline_link" onClick={onBackStep1Clicked}>Return to Step #1</a> and re-download & re-install MDM profile.</li>
                        <li><a className="setup__inline_link" onClick={onBackClicked}>Return to Step #2</a> and check installation process. </li>
                      </ul>

                      <div className="setup__button_download">
                        <PrimaryButton 
                          onClick={onCheckAgainClicked} 
                          isPurple={true}
                          title={"Check Again"}/>
                      </div>
                    </div>
                  )}
                  
                </div>
              )}
    
            </div>
          )} 
        </div>
      
        <div className="setup__action-container columns is-mobile">
          <PrimaryButton
            onClick={onContinueClicked}
            readyToCheck={!! canContinue}
            showFinger={canContinue}
            title={"Continue"}
          />
        </div>
      </div>
    </>
  );
};

export default OnBoardingBlock;
