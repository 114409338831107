import React, { useState } from "react";

import QuestionTopBar from "modules/difficulty/QuestionTopBar";
import QuestionImagesActions from "modules/difficulty/QuestionImagesActions";
import { VoidFunction } from "types/generic";
import LightboxImage from "components/LightboxImage";
import { Quiz } from "types/quiz";

interface Props {
  quiz: Quiz;
  onBackClicked: VoidFunction;
  onContinueClicked: VoidFunction;
  setLoading: VoidFunction;
  hasIntro: boolean;
  count: number;
}

const QuestionImages = (props: Props) => {
  const { count, quiz, onBackClicked, onContinueClicked, setLoading, hasIntro } = props;

  const [currentImage, setCurrentImage] = useState<number>(0);

  const currentImageDisplay: number = currentImage + 1;
  const isFinalImage: boolean = currentImage === count - 1;

  const onNextClicked = () => {
    setCurrentImage((currentImage) => currentImage + 1);
  };

  const onPreviousClicked = () => {
    setCurrentImage((currentImage) => currentImage - 1);
  };
  const quizImages = quiz.images;
  
  if (!quizImages[currentImage]) {
    setLoading();
    return (
      <>
        <QuestionTopBar backToArticle={hasIntro} onBackClicked={onBackClicked} />
        <div className="question-images">
          <div className="question-images__container">
            <div className="question-images__header">
              <p className="question-images__header--count">
                Image {currentImageDisplay} of {count}
              </p>
            </div>
            <div className="question-loader-parent">
              <div className="loader question-loader"></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const imageType = quizImages[currentImage].image_type;
  let imageData = null;
  if (imageType) {
    imageData = quizImages[currentImage].image_data;
    if (imageType.toLowerCase().includes("svg")) {
      imageData = btoa(imageData);
    }
    imageData = "data:" + imageType + ";base64," + imageData;
  }

  return (
    <>
      <QuestionTopBar backToArticle={hasIntro} onBackClicked={onBackClicked} />
      <div className="question-images column">
      <div className="question-images_fullscreen">
          <div className="question-images__header">
            <p className="question-images__header--count">
              Image {currentImageDisplay} of {count}
            </p>
          </div>
          <div className="question-images__container">
            <LightboxImage imageURL={imageData} />

            {quizImages[currentImage].description && (
              <p className="question-images__header--description">
                {quizImages[currentImage].description}
              </p>
            )}
          </div>
          </div>  
        <QuestionImagesActions
          currentImage={currentImage}
          isFinalImage={isFinalImage}
          onContinueClicked={onContinueClicked}
          onNextClicked={onNextClicked}
          onPreviousClicked={onPreviousClicked}
        />
      </div>
    </>
  );
};

export default QuestionImages;
