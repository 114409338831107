import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import PrimaryButton from "components/PrimaryButton";
import { boldAfterFirstWord } from "utils/difficulty/boldAfterFirstWord";
import usePostRequest from "api/usePostRequest";
import { VoidFunction } from "types/generic";
import { goHome, navigateTo } from "packs/application";
import QuestionHeader from "modules/difficulty/QuestionHeader";
import AnswerButton from "modules/difficulty/AnswerButton";
import { Question } from "types/question";
import { Result } from "types/result";

import imgFailure from "assets/images/lesson-results/squirrel.svg";
import imgSuccess from "assets/images/lesson-results/congratsBear.svg";
import imgHomeIcon from "assets/images/lesson-results/kidsHeader.svg";

interface Props {
  childId: number;
  quizId: number;
  lessonId: number;
  resetQuiz: VoidFunction;
  questions: Question[];
  isReadingType: boolean;
}

const LessonResults = (props: Props) => {
  const { childId, quizId, resetQuiz, questions, isReadingType } = props;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [result, setResult] = useState<Result>(null);
  
  const FAILURE_HEADER = "We know you can do better, try again!";
  const SUCCESS_HEADER = "You Did It!";
  const FAILURE_SUBHEADER = "Gotta Learn It 2 Earn It!";
  const SUCCESS_SUBHEADER = "You Earned It!";

  const successfulLesson: boolean = result && result.percentage_correct >= 70;
  const percentCorrect: number = result 
    ? result.percentage_correct 
    : 0;
  const minutesEarned: number = result 
    ? Math.round(result.seconds_earned / 60) 
    : 0;

  const RESULTS_IMAGE = successfulLesson ? imgSuccess : imgFailure;
  const HEADER_TEXT = successfulLesson ? SUCCESS_HEADER : FAILURE_HEADER;
  const SUBHEADER_TEXT = successfulLesson
    ? SUCCESS_SUBHEADER
    : FAILURE_SUBHEADER;

  useEffect(() => {
    sendResults();
  }, []);

  const [sendResults, isSendResultsLoading] = usePostRequest({
    dispatch,
    dispatchToStore: false,
    url: `/app/results`,
    body: {
      results: {
        quiz_id: quizId,
        child_id: childId,
        answers: questions.map(q => q.answer.id),
      },
    },
    onSuccess: (data) => {
      let result = data as Result;
      if (result) {
        setResult(result);
        setIsLoading(false);
      }
    },
    onFailure: (err) => {
      // resend data
      setTimeout(function() {
        sendResults();
      }, 2000)
    },
  });

  function continueQuiz(e) {
    return navigateTo("/app/groups/", e);
  }

  function resetOrContinueQuiz(e) {
    if (result.downgraded) {
      return navigateTo("/app/groups", e);
    } else {
      resetQuiz();
    }
  }

  const renderActionButton = () => {
    if (!successfulLesson) {
      return (
        <div className="column lesson-results__try-again">
          <PrimaryButton onClick={resetOrContinueQuiz} readyToCheck title="Try Again" />
        </div>
      );
    } else {
      return (
        <div className="column lesson-results__try-again">
          <PrimaryButton onClick={continueQuiz} readyToCheck title="Continue" />
        </div>
      );
    }
  };

  const renderResults = () => {
    return (
      questions.map((question, index) => {
        const { answer } = question;

        if (result && !result.incorrect_answers.includes(answer.id)) {
          return (<div key={question.id}></div>);
        }

        return (
          <div className="lesson-results__question_block" key={question.id}>
            <div className="lesson-results__question_header">
              Question {index + 1}
            </div>
            <div className="lesson-results__question">
              <QuestionHeader
                content={question.content}
                content_images={question.content_images}
                image_data={question.image_data}
                image_type={question.image_type}
                sub_content={question.sub_content}
                sub_content_images={question.sub_content_images}
              />
            </div>
            <div className="answers">
              <div className="answers__container">
                <AnswerButton
                  answer={answer}
                  isReadingType={isReadingType}
                  isShowingAnswer={true}
                  selectedAnswer={answer.id}
                />
              </div>
            </div>
          </div>
        );
      })
    );
  }

  const renderLoader = () => {
    return (
      <>
        <div className="lesson-results__top--content">
          <h2 className="lesson-results__header--large">
            Loading...
          </h2>

          <div className="loader lesson-results__loader"></div>
        </div>
      </>
    );
  }

  return (
    <div className="lesson-results">
      <div
        className={classnames("lesson-results__top", {
          "lesson-results__top--success": successfulLesson,
        })}
      >
        <div className="lesson-results__home">
          <a onClick={goHome}>
            <img src={imgHomeIcon} />
          </a>
        </div>

        {isLoading &&(
          <>
            {renderLoader()}
          </>
        )}

        {!isLoading &&(
          <div className="lesson-results__top--content">
            <img src={RESULTS_IMAGE} />

            <h2
              className={classnames("lesson-results__header", {
                "lesson-results__header--large": successfulLesson,
              })}
            >
              {HEADER_TEXT}
            </h2>
            <h3 className="lesson-results__subheader">
              {boldAfterFirstWord(SUBHEADER_TEXT)}
            </h3>
          </div>
        )}
      </div>
      
      {!isLoading &&(
        <>
          <div className="lesson-results__bottom columns is-centered is-mobile">
            <div className="lesson-results__summary">
              <div className="lesson-results__minutes-container">
                <span className="lesson-results__minutes-earned">
                  {minutesEarned}
                </span>
                <span className="lesson-results__minutes-label">Min</span>
              </div>
              <p className="lesson-results__percent">
                {percentCorrect}% on your lesson
              </p>
            </div>

            {renderActionButton()}
          </div>

          {percentCorrect !== 100 && (
            <>
              <div className="lesson-results__bottom_questions">
                {renderResults()}
                {renderActionButton()}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LessonResults;
