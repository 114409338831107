import React, { Component } from 'react'

const IMAGE_INDEX_PREFIX = "[IMAGE_INDEX]";
const IMAGE_INDEX_SUFFIX = "[/IMAGE_INDEX]";

export function getMaxHeight(images) {
  if (images!= null && images.length > 0)
  {
    let height = 0;
    for (let i = 0; i < images.length; i++) {
      const currentHeight = parseInt(images[i].height);
      if (currentHeight > height) {
        height = currentHeight;
      }
    }
    
    return height;
  } 
  return 0; 
}

function splitParts(str, search) {
  let ret = [];

  const index = str.indexOf(search);
  if (index >= 0) {
    const left = str.substring(0, index);
    const middle = str.substring(index, index + search.length);
    const right = str.substring(index + search.length);

    if (left !== "") {
        ret = ret.concat(splitParts(left, search));
      }
      ret.push(middle);
      if (right !== "") {
        ret = ret.concat(splitParts(right, search));
      }
    } else {
      ret.push(str);
    }
  
    return ret;
  }
  
  function splitPartsArray(strArray, searchArray) {
    for (let i = 0; i < strArray.length; i++) {
      const currentSearch = searchArray[i];

      let newArr = [];
      for (let j = 0; j < strArray.length; j++) {
        const currentStr = strArray[j];
        newArr = newArr.concat(splitParts(currentStr, currentSearch));
      }
      strArray = newArr;
    }
  
    return strArray;
  }

export function renderFormulaContent(content, images, zoomMultiplier, minHeight) {
  let i;
  let imagesLength;
  let contentParts = [content];
  const replaceParts = [];

  if (images != null && images.length > 0) {
      imagesLength = images.length;
      
      for (i = 0; i < imagesLength; i++) {
        replaceParts.push(IMAGE_INDEX_PREFIX + i + IMAGE_INDEX_SUFFIX);
      }

      contentParts = splitPartsArray(contentParts, replaceParts);
    }

    let height = getMaxHeight(images) * zoomMultiplier;
    if (minHeight > height) {
      height = minHeight;
    }
    let items = []
    for (i = 0; i < contentParts.length; i++) {
      const part = contentParts[i];
      let svgIndex = -1;

      if (images != null && images.length > 0) {
        imagesLength = images.length;

        for (let j = 0; j < imagesLength && svgIndex < 0; j++){
          const svg_string = IMAGE_INDEX_PREFIX + j + IMAGE_INDEX_SUFFIX;
          if (part == svg_string) {
            svgIndex = j;
          }
        }
      }

      const keyName = "formulaItem" + i;
      const className = "formula-image";
      if (svgIndex >= 0) {
        const image = images[svgIndex];
        const imageData = "data:image/svg+xml;base64," + btoa(image.data);
        items.push(<img key={keyName} className={className} height={height} width={image.width * zoomMultiplier} src={imageData}></img>)
      } else { 
        items.push(<span key={keyName}>{part}</span>)
      }
    }

    return items;
}

export function renderFormulaStyles(images, zoomMultiplier, minHeight) {
  if (images!= null && images.length > 0)
  {
    let height = minHeight;
    const currentHeight = getMaxHeight(images) * zoomMultiplier;
    if (currentHeight > height) {
      height = currentHeight * 0.8;
    }
    
    return { lineHeight: height + "px"}
  } 
  return {}; 
}