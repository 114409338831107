import { Api } from "types/api";

const initialState: Api = {};

interface Payload {
  data: any;
  options: {
    key: string;
  };
}

interface Action {
  type: string;
  payload: Payload;
}

export type ApiAction = { type: "API_FETCH_SUCCESS"; payload: Payload };

export default function reducer(
  state: Api = initialState,
  action: Action
): Api {
  const { type, payload } = action;

  switch (type) {
    case "API_FETCH_SUCCESS":
      return {
        ...state,
        [payload.options.key]: payload.data,
      };
    case "API_POST_SUCCESS_ADD":
      return {
        ...state,
        [payload.options.key]: [...state[payload.options.key], payload.data],
      };
    default:
      return state;
  }
}
