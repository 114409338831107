import React from "react";
import classnames from "classnames";

import { VoidFunction } from "types/generic";

import imgFinger from "assets/images/icons/finger.svg"

interface Props {
  isLightBlue?: boolean;
  onClick: VoidFunction;
  readyToCheck?: boolean;
  title: string;
  isPurple?: boolean;
  showFinger?: boolean;
  showFloatFinger?: boolean;
}

const PrimaryButton = (props: Props) => {
  const { isLightBlue, onClick, readyToCheck, title, isPurple, showFinger, showFloatFinger } = props;

  return (
    <>
      <button
        className={classnames("column", "primary-button", {
          "primary-button__is-light-blue": isLightBlue,
          "primary-button__ready": readyToCheck,
          "primary-button__is-purple": isPurple,
        })}
        onClick={onClick}
      >
        <span>{title}</span>
        {showFinger && (
        <img className="primary-button__finger" src={imgFinger} />
      )}
      </button>
      {showFloatFinger && (
        <img className="primary-button__float_finger" src={imgFinger} />
      )}
    </>
  );
};

export default PrimaryButton;
