import React, { useState } from "react";

import Answers from "modules/difficulty/Answers";
import PrimaryButton from "components/PrimaryButton";
import { VoidFunction } from "types/generic";
import { Question } from "types/question";
import { Quiz } from "types/quiz";
import QuestionTopBar from "modules/difficulty/QuestionTopBar";
import QuestionHeader from "modules/difficulty/QuestionHeader";
import QuestionIntro from "modules/difficulty/QuestionIntro";
import QuestionImages from "modules/difficulty/QuestionImages";

interface Props {
  checkAnswer: VoidFunction;
  currentQuestion: number;
  quiz: Quiz;
  quizType: string;
  loadNextChunk: VoidFunction;
  percentComplete: number;
  question: Question;
  totalQuestions?: number;
  setLoading: VoidFunction;
}

const QuestionBlock = (props: Props) => {
  const {
    checkAnswer,
    currentQuestion,
    quiz,
    quizType,
    loadNextChunk,
    setLoading,
    percentComplete,
    question,
    totalQuestions,
  } = props;
  const { intro, title, has_images } = quiz;
  
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const [isShowingIntro, setIsShowingIntro] = useState<boolean>(true);
  const [isShowingImages, setIsShowingImages] = useState<boolean>(false);

  const isReadingType: boolean = quizType === "reading_comprehension";
  const hasIntroImages: boolean = has_images;
  const hasIntroText: boolean = intro != "";
  const hasImagesOrReading: boolean = hasIntroImages || hasIntroText;
  
  const onAction = () => {
    if (selectedAnswer) {
      checkAnswer(selectedAnswer);
      setSelectedAnswer(null);
    }
  };

  const onIntroContinueClicked = () => {
    if (hasIntroImages) {
      setIsShowingIntro(false);
      setIsShowingImages(true);
    } else {
      setIsShowingIntro(false);
    }
  };

  const onImagesContinueClicked = () => {
    setIsShowingIntro(false);
    setIsShowingImages(false);
  };

  const onQuestionTopBarBackClicked = () => {
    if (hasIntroImages) {
      setIsShowingIntro(false);
      setIsShowingImages(true);
    } else {
      setIsShowingIntro(true);
      setIsShowingImages(false);
    }
  };

  if (isShowingImages) {
    return (
      <QuestionImages
        quiz={quiz}
        onBackClicked={() => {
          setIsShowingImages(false);
          setIsShowingIntro(true);
        }}
        onContinueClicked={onImagesContinueClicked}
        hasIntro={intro != ""}
        count={quiz.images_count}
        setLoading={setLoading}
      />
    );
  }

  if (isShowingIntro) {
    if (hasIntroText) {
      return (
        <QuestionIntro
          intro={intro}
          percentComplete={percentComplete}
          onIntroContinueClicked={onIntroContinueClicked}
          title={title}
        />
      );
    } else if (hasIntroImages) {
      setIsShowingImages(true);
    }
  }

  if (currentQuestion == 0) {
    loadNextChunk();
  }

  if (!question) {
    setLoading();
    return (
      <>
        <QuestionTopBar
          backToArticle={hasImagesOrReading}
          hasIntroImages={hasIntroImages}
          onBackClicked={onQuestionTopBarBackClicked}
          percentComplete={percentComplete}
        />
        <div className="question column">
          <div className="answers">
            <div>
              <div className="loader question-loader"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <QuestionTopBar
        backToArticle={hasImagesOrReading}
        hasIntroImages={hasIntroImages}
        onBackClicked={onQuestionTopBarBackClicked}
        percentComplete={percentComplete}
      />
      <div className="question column">
        <QuestionHeader
          content={question.content}
          content_images={question.content_images}
          currentQuestion={currentQuestion + 1}
          image_data={question.image_data}
          image_type={question.image_type}
          sub_content={question.sub_content}
          sub_content_images={question.sub_content_images}
          totalQuestions={totalQuestions}
        />

        <Answers
          answers={question.answers}
          isReadingType={isReadingType}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
        />
        <div className="question__action-container columns is-mobile">
          <PrimaryButton
            onClick={onAction}
            readyToCheck={!!selectedAnswer}
            title="Continue"
          />
        </div>
      </div>
    </>
  );
};

export default QuestionBlock;
