import React from "react";

import { VoidFunction } from "types/generic";
import PrimaryButton from "./../../components/PrimaryButton";

interface Props {
  currentImage: number;
  isFinalImage: boolean;
  onContinueClicked: VoidFunction;
  onNextClicked: VoidFunction;
  onPreviousClicked: VoidFunction;
}

const QuestionImagesActions = (props: Props) => {
  const {
    currentImage,
    isFinalImage,
    onContinueClicked,
    onNextClicked,
    onPreviousClicked,
  } = props;

  const canShowBack: boolean = currentImage > 0;

  const renderBack = () => {
    if (canShowBack) {
      return (
          <PrimaryButton isLightBlue onClick={onPreviousClicked} title="Back" />
      );
    } else {
      return <div className="column question-images__action_back" />;
    }
  };

  const renderNextContinue = () => {
    if (isFinalImage) {
      return (
        <PrimaryButton
          onClick={onContinueClicked}
          readyToCheck
          title="Continue"
        />
      );
    } else {
      return (
        <PrimaryButton onClick={onNextClicked} readyToCheck title="Next" />
      );
    }
  };

  return (
    <div className="question-images__actions columns is-mobile">
      {renderBack()}
      {renderNextContinue()}
    </div>
  );
};

export default QuestionImagesActions;
