import React from "react";

import { VoidFunction } from "types/generic";
import { goHome } from "../../../packs/application";

import imgXIcon from "assets/images/icons/x.svg";
import imgCaretIcon from "assets/images/icons/caret-left.svg";


interface Props {
  backToArticle?: boolean;
  hasIntroImages?: boolean;
  onBackClicked?: VoidFunction;
  percentComplete?: number;
}

const QuestionTopBar = (props: Props) => {
  const {
    backToArticle,
    hasIntroImages,
    onBackClicked,
    percentComplete,
  } = props;

  if (backToArticle) {
    return (
      <div className="question__top-bar--article">
        <button
          className="question__top-bar--article-button"
          onClick={onBackClicked}
        >
          <img src={imgCaretIcon} />
          Back to {hasIntroImages ? "Images" : "Article"}
        </button>

        <a onClick={goHome} className="question__top-bar--quit">
          Quit
        </a>
      </div>
    );
  }

  return (
    <div className="question__top-bar">
      <div style={{ width: "33%" }}>
        <a onClick={goHome}>
          <img src={imgXIcon} />
        </a>
      </div>

      <div className="question__progress-bar">
        <span style={{ width: `${percentComplete}%` }}></span>
      </div>

      <div style={{ width: "33%" }} />
    </div>
  );
};

export default QuestionTopBar;
