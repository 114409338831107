import React from "react";

import AnswerButton from "modules/difficulty/AnswerButton";
import { Answer } from "types/answer";
import { VoidFunction } from "types/generic";

interface Props {
  answers: Answer[];
  isReadingType: boolean;
  selectedAnswer: number;
  setSelectedAnswer: VoidFunction;
}

const Answers = (props: Props) => {
  const {
    answers,
    isReadingType,
    selectedAnswer,
    setSelectedAnswer,
  } = props;

  return (
    <div className="answers">
      <h3 className="answers__header">Answer</h3>
      <div className="answers__container">
        {answers.map((answer) => {
          return (
            <AnswerButton
              answer={answer}
              isReadingType={isReadingType}
              key={answer.id}
              selectedAnswer={selectedAnswer}
              setSelectedAnswer={setSelectedAnswer}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Answers;
