import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

interface Props {
  imageURL: string;
}

const LightboxImage = (props: Props) => {
  const { imageURL } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <button
        className="question-images__button"
        onClick={() => setIsOpen(true)}
      >
        <img src={imageURL} />
      </button>
      {isOpen && (
        <Lightbox mainSrc={imageURL} onCloseRequest={() => setIsOpen(false)} />
      )}
    </>
  );
};

export default LightboxImage;
