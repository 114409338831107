import { call, takeEvery, put } from "redux-saga/effects";
import get from "lodash/get";
import Saga from "types/Saga";
import api from "../../api/Api";

interface RequestAction extends Saga {
  payload: {
    url: string;
    dispatchToStore: boolean;
    key?: string;
    params: object;
  };
}

interface PostRequestAction extends Saga {
  payload: {
    url: string;
    dispatchToStore: boolean;
    key?: string;
    body: object;
    storeData?: object;
    actionType?: string;
    config?: object;
  };
}

interface DeleteRequestAction extends Saga {
  payload: {
    url: string;
  };
}

export function* getData(action: RequestAction) {
  try {
    const { url, key, params } = action.payload;

    const response = yield call(api.utility.get, url, { params });

    const parsedData = response.data;

    if (action.payload.dispatchToStore) {
      yield put({
        type: "API_FETCH_SUCCESS",
        payload: {
          options: {
            key: key,
          },
          data: parsedData,
        },
      });
    }

    if (action.onSuccess) {
      action.onSuccess(response.data);
    }
  } catch (err) {
    if (action.onFailure) {
      const responseErr = get(err, "response.errors");

      action.onFailure(responseErr);
    }
  } finally {
    if (action.onComplete) {
      action.onComplete();
    }
  }
}

export function* postData(action: PostRequestAction) {
  try {
    const { url, key, body } = action.payload;

    const response = yield call(api.utility.post, url, body);

    const dataForStore = action.payload.storeData
      ? action.payload.storeData
      : response.data;

    if (action.payload.dispatchToStore) {
      if (action.payload.actionType === "add") {
        yield put({
          type: "API_POST_SUCCESS_ADD",
          payload: {
            options: {
              key: key,
            },
            data: dataForStore,
          },
        });
      } else {
        yield put({
          type: "API_POST_SUCCESS",
          payload: {
            options: {
              key: key,
            },
            data: dataForStore,
          },
        });
      }
    }

    if (action.onSuccess) {
      action.onSuccess(response.data);
    }
  } catch (err) {
    if (action.onFailure) {
      const responseErr = get(err, "response.errors");

      action.onFailure(responseErr);
    }
  } finally {
    if (action.onComplete) {
      action.onComplete();
    }
  }
}

export function* putData(action: PostRequestAction) {
  try {
    const { url, body, config } = action.payload;

    const response = yield call(api.utility.put, url, body, config);

    if (action.onSuccess) {
      action.onSuccess(response.data);
    }
  } catch (err) {
    if (action.onFailure) {
      const responseErr = get(err, "response.errors");

      action.onFailure(responseErr);
    }
  } finally {
    if (action.onComplete) {
      action.onComplete();
    }
  }
}

export function* deleteData(action: DeleteRequestAction) {
  try {
    const { url } = action.payload;

    yield call(api.utility.delete, url);

    if (action.onSuccess) {
      action.onSuccess();
    }
  } catch (err) {
    const responseErr = get(err, "response.errors");

    if (action.onFailure) {
      action.onFailure(responseErr);
    }
  } finally {
    if (action.onComplete) {
      action.onComplete();
    }
  }
}

export function* getQuiz(action) {
  try {
    const { id, image_position, question_position, onSuccess } = action.payload;
    const url = `/app/api/${id}?i_pos=${image_position}&q_pos=${question_position}`;

    const response = yield call(api.utility.get, url);
    
    if(onSuccess) {
      onSuccess(response.data);
    }
  }
  catch (err) {
    if (action.onFailure) {
      const responseErr = get(err, "response.errors");
      action.onFailure(responseErr);
    }
  } finally {
    if (action.onComplete) {
      action.onComplete();
    }
  }
}

export default function* requestSaga() {
  yield takeEvery("GET_DATA_SAGA", getData);
  yield takeEvery("POST_DATA_SAGA", postData);
  yield takeEvery("PUT_DATA_SAGA", putData);
  yield takeEvery("DELETE_DATA_SAGA", deleteData);

  yield takeEvery('GET_QUIZ_SAGA', getQuiz);
}
