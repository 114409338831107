import React, { useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { Child } from "types/child";
import { Device } from "types/device";
import { composeWithRedux } from "../../../packs/application.js";

import OnBoardingBlock from "modules/setup/OnBoardingBlock";
import { Plan } from "types/plan";
interface Props {
  child: Child;
  device: Device;
  position: number;
  has_lock_permission: boolean;
  plan: Plan;
}

const onContinueClicked = () => {
}

const SetupScreen = (props: Props) => {
  const { child, device, position, has_lock_permission, plan } = props;

  return (
    <div className="quiz">
      <OnBoardingBlock 
        onParentContinueClicked={onContinueClicked}
        device={device}
        child={child}
        setup_position={position}
        has_lock_permission={has_lock_permission}
        plan={plan}
        />
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupScreen);

export default (props) => composeWithRedux(ConnectedComponent, props);
