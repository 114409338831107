import { combineReducers } from "redux";

import api from "./api";

const allReducers = combineReducers({
  api,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
